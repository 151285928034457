const Privacy = () => {
    return (
        <div className="p-8 mt-16 max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
            <p className="mb-4">
                This Privacy Policy outlines how Supersite ("we", "our", or "us") collects, uses, discloses, and protects personal information obtained from users ("you", "your") of the Supersite platform and website. We are committed to safeguarding your privacy and ensuring the security of your personal information. By accessing or using Supersite, you consent to the practices described in this Privacy Policy.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Information We Collect</h2>
            <p className="mb-4">
                We may collect the following types of personal information from you:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>Contact information, including name, email address, and phone number.</li>
                <li>Account information, such as username and password.</li>
                <li>Payment information, if applicable, for processing payments for services.</li>
                <li>Usage data and analytics, including IP address, browser type, device information, and website interactions.</li>
                <li>Communications and correspondence, such as emails, messages, or feedback provided to us.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">How We Use Your Information</h2>
            <p className="mb-4">
                We use the collected information for the following purposes:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>To provide and improve our services, products, and customer support.</li>
                <li>To process payments and maintain billing records.</li>
                <li>To communicate with you about updates, changes, or promotions related to Supersite.</li>
                <li>To personalize your experience and tailor content and recommendations based on your preferences.</li>
                <li>To monitor and analyze website usage and performance for internal purposes.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Information Sharing and Disclosure</h2>
            <p className="mb-4">
                We do not sell, trade, or rent your personal information to third parties without your consent. However, we may share your information with trusted service providers, business partners, or contractors who assist us in delivering our services, subject to confidentiality obligations. We may also share your information to comply with legal obligations, enforce our policies, or protect our rights and safety.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Data Security</h2>
            <p className="mb-4">
                We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Cookies and Tracking Technologies</h2>
            <p className="mb-4">
                Supersite may use cookies and similar technologies to enhance user experience, track website usage, and gather information about your preferences. You can manage your cookie preferences through your browser settings.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Third-Party Links</h2>
            <p className="mb-4">
                Supersite may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites. Please review the privacy policies of such third-party websites before providing any personal information.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Children's Privacy</h2>
            <p className="mb-4">
                Supersite is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us to delete the data.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Changes to this Privacy Policy</h2>
            <p className="mb-4">
                We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Effective Date" at the top of the policy will be revised accordingly.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
            <p className="mb-4">
                If you have any questions, concerns, or requests related to this Privacy Policy or the handling of your personal information, please contact us at <a href="mailto:hello@supersite.app" className="text-blue-500">hello@supersite.app</a>.
            </p>

            <p className="mt-6">
                By using Supersite, you acknowledge and agree to the terms outlined in this Privacy Policy. Please read this policy carefully and review it regularly for any updates.
            </p>
        </div>
    );
}

export default Privacy;