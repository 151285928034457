import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { lazy } from "react";
import AppHeader from './components/shared/AppHeader'
import AppFooter from "./components/shared/AppFooter";
import HomePage from "./pages/HomePage";
import ContactUs from "./pages/ContactUs";
import RealEstate from "./pages/RealEstate";
import Infrastructure from "./pages/Infrastructure";
import SteelStructure from "./pages/SteelStructure";
import Privacy from "./pages/Privacy";
const NotFound = lazy(() => import("./pages/NotFound"));

const App = () => {
	return (
		<Router>
			<AppHeader />
			<main className="flex-grow">
				<Suspense fallback={<div>Loading...</div>}>
					<Routes>
						<Route path="/" element={<HomePage />} end />
						<Route path="/contact" element={<ContactUs />} />
						<Route path="/privacy" element={<Privacy />} />
						<Route
							path="/industries/real-estate"
							element={<RealEstate />}
						/>
						<Route
							path="/industries/steel-structure"
							element={<SteelStructure />}
						/>
						<Route
							path="/industries/infrastructure"
							element={<Infrastructure />}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>
			</main>
			<AppFooter />
		</Router>
	);
};
export default App;
